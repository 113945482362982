<template>
  <section class="!py-lg md:!py-2xl bg-white">
    <Heading tag="h2" align="center" size="md" class="mb-sm md:mb-2xl">Meet the Team</Heading>

    <LazySlider
      :space-between="48"
      center-insufficient-slides
      :draggable="false"
      :autoplay="false"
      :breakpoints="breakpoints"
      class="wrapper-left !py-none"
    >
      <swiper-slide v-for="(member, index) in teamMembers(page)" :key="`members-${index}`" class="pb-2xs">
        <SimpleCard remove-padding rounded>
          <NuxtImg
            :src="member.image"
            class="rounded-tl-sm rounded-tr-sm w-full h-[300px] object-cover object-top aspect-[2/3]"
            :alt="`${member.string1} headshot image`"
          />
          <div class="p-xs sm:p-sm h-[100px] sm:h-[130px] md:h-[120px]">
            <Text weight="bold" align="center">{{ member.name }}</Text>
            <Text align="center">{{ member.string1 }}</Text>
          </div>
        </SimpleCard>
      </swiper-slide>
    </LazySlider>
  </section>
</template>

<script setup lang="ts">
import { SwiperSlide } from "swiper/vue";
import { BranchMembersProps, PageData } from "./branchDetailsTypes";
const breakpoints = {
  320: {
    slidesPerView: 2.6,
    spaceBetween: 48,
  },
  360: {
    slidesPerView: 1.6,
    spaceBetween: 48,
  },
  520: {
    slidesPerView: 2.4,
    spaceBetween: 48,
  },
  768: {
    slidesPerView: 3.4,
    spaceBetween: 24,
  },
  960: {
    slidesPerView: 4.4,
    spaceBetween: 24,
  },
  1000: {
    slidesPerView: 5.2,
    spaceBetween: 24,
  },
};
const props = defineProps<BranchMembersProps>();

const { page } = toRefs(props);

const content = (page: PageData) => {
  return page.getDocument()?.getData();
};

const teamMembers = (page: PageData) => {
  return (content(page)?.team || [])
    .filter((teamMember: any) => teamMember.image != null && teamMember.string.length > 0)
    .map((teamMember: any) => ({
      ...teamMember,
      name: teamMember.string,
      role: teamMember.string1,
      image: teamMember.image?.$ref && page.getContent(teamMember.image?.$ref)?.original?.model?.links?.site?.href,
    }));
};
</script>
